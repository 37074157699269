<template>
  <v-container fluid>
    <div v-for="student in students" :key="student.uid">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p >コース選択確認</p>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column" v-if="student.existFlg">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              {{ student.userNm }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              大学区分：{{ student.daigakuKbnName }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              志望する文理コース：{{ student.sentakuCourseCdName }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(数学)：{{ student.yobi1 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(理科)：{{ student.yobi2 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(地公)：{{ student.yobi3 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(芸術)：{{ student.yobi4 }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column" v-if="!student.existFlg">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              {{ student.userNm }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              まだコース選択がされていません
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      students: [],
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.$root.loading = true;
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

    const data = {
      funcName: "loadCourseSelectParent"
    };

    await schoolAidHomeLogined(data).then((res) => {
      this.students = res.data;
    }).catch((e) =>{
      this.error = e.message;
      this.$root.loading = false;
    });

    // ローディング終了
    this.$root.loading = false;
  },
};
</script>