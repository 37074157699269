<template>
  <v-container fluid>
    <v-alert
      v-if="!mailAddressAuthFlg"
      dense
      border="left"
      type="info"
      dismissible
    >
      左上メニューからメールアドレス設定をクリックして、メールアドレスの登録をお願いいたします。
    </v-alert>
    <v-alert
      v-if="processingFlg"
      dense
      border="left"
      type="info"
      dismissible
    >
      早退された生徒が自宅にお戻りになりましたら、帰宅連絡ボタンを押してください。
    </v-alert>

    <v-row dense v-if="this.isViewSotai">
      <v-col cols="12">
        <v-data-table
          :headers="sotaiheaders"
          :items="sotailist"
          :items-per-page="-1"
          caption="早退連絡"
          dense
          disable-sort
          hide-default-footer
          mobile-breakpoint="0"
        >
          <!-- 生徒氏名とモバイル用まとめ -->
          <template #[`item.name`]="{ item }">
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12">
                {{ item.name }}
              </v-col>
            </v-row>

            <!-- モバイル用まとめ -->
            <v-row dense class="d-sm-none">
              <v-col cols="12">
                <!-- １行目（生徒氏名） -->
                <v-row no-gutters>
                  <v-col cols="12" class="text-caption">
                    {{ item.name }}
                  </v-col>
                </v-row>
                <!-- ２行目（早退事由） -->
                <v-row no-gutters>
                  <v-col cols="12" class="text-caption">
                    {{ item.leavingReason }}
                  </v-col>
                </v-row>
                <!-- ３行目（下校開始予定時刻＋早退時刻） -->
                <v-row no-gutters>
                  <v-col cols="4" class="text-caption">
                    予定時刻:{{ item.leavingTime }}
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center text-caption">
                    <span v-if="item.gatePassageTime">早退時刻:{{ item.gatePassageTime }}</span>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end text-caption">
                    帰宅連絡：<span v-if="item.arrivalStatusFlg">済</span><span v-if="!item.arrivalStatusFlg">未</span>
                  </v-col>
                </v-row>
                <!-- ４行目（ボタン類） -->
                <v-row no-gutters>
                  <v-col cols="6" class="text-caption">
                    <v-btn color="accent" small class="black--text" @click="onClickContactSotai(item)">確認</v-btn>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-end text-caption">
                    <v-btn color="accent" small class="black--text" @click="onChangeArrivalStatus(item)" :disabled="item.arrivalStatusFlg">帰宅連絡</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="onClickContactSotai(item)">確認</v-btn>
          </template>
          <template #[`item.arrivalStatusFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.arrivalStatusFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.actions2`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="onChangeArrivalStatus(item)" :disabled="item.arrivalStatusFlg">帰宅連絡</v-btn>
          </template>
      </v-data-table>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-tabs fixed-tabs v-model="tab">
          <v-tab href="#healthRoomContact">
            <v-btn color="red" small icon outlined v-if="unreadHealthRoomCount > 0 && unreadHealthRoomCount <= 99">{{ unreadHealthRoomCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadHealthRoomCount > 99">99+</v-btn>
            保健室からの連絡
          </v-tab>
          <v-tab href="#contact">
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 0 && unreadContactCount <= 99">{{ unreadContactCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 99">99+</v-btn>
            学校からの連絡
          </v-tab>
          <v-tab href="#survey">
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 0 && unreadSurveysCount <= 99">{{ unreadSurveysCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 99">99+</v-btn>
            アンケート
          </v-tab>
          <v-tab href="#talkRoom">
            <v-btn color="red" small icon outlined v-if="unreadTalkRoomCount > 0 && unreadTalkRoomCount <= 99">{{ unreadTalkRoomCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadTalkRoomCount > 99">99+</v-btn>
            個別連絡
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="healthRoomContact" eager>
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="healthFilterDialog = true" small>
                  <v-icon right dark v-if="healthFilterFlg">mdi-filter</v-icon>
                  <v-icon right dark v-if="!healthFilterFlg">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="healthTitleFilterValue"
                  label="ワード検索"
                  placeholder="ワード検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="healthViewUnreadOnly"
                  @click="healthChangeFilter(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col> 
            </v-row>

            <v-data-table
              :headers="healthRoomheaders"
              :items="healthRoomList"
              :items-per-page="-1"
              dense
              ref="healthRoomTable"
              disable-sort
              hide-default-footer
              group-by="studentNm"
              @click:row="onClickHealthRoomDetails"
              :item-class="rowStyleHealthRoom"
              :loading="isHealthRoomLoding" loading-text="読込中"
              :search="healthTitleFilterValue"
              :custom-filter="healthRoomTitleFilter"
              mobile-breakpoint="0"
              item-key="raishitsuId"
            >
              <template #[`group.header`]="{group, headers, toggle, isOpen, items}">
                <td @click="toggle" :colspan="headers.length">
                  <v-btn icon>
                    <v-icon v-if="isOpen">mdi-minus</v-icon>
                    <v-icon v-else>mdi-plus</v-icon>
                  </v-btn>
                  {{ group }}（件数：{{ getCountHealthRoom(items, group) }} 未読：{{ getUnreadCountHealthRoom(items, group) }}）
                </td>
              </template>

              <!-- タイトルとモバイル用まとめ -->
              <template #[`item.healthRoomheaders`]="{ item }">
                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(症状＋コメント) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="text-caption">
                        {{ item.shoujo }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption">
                        {{ item.comment }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(メールタイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.mailTitle }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(来室日時日＋既読状況) -->
                    <v-row no-gutters>
                      <v-col cols="9" class="text-caption">
                        公開:{{ item.postDate }}
                      </v-col>
                      <v-col cols="3" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- タイトル -->
              <template #[`item.mailTitle`]="{ item }">
                {{ item.mailTitle }}
              </template>
              <!-- 来室日時 -->  
              <template #[`item.postDate`]="{ item }">
                {{ item.postDate }}
              </template>             
              <!-- 更新日時と更新者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.userNm }}</v-col></v-row>
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.updDate }}</v-col></v-row>
              </template>                
              <!-- 既読/未読 -->
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.unreadState }}
              </template>
              <!-- 本文 -->
              <template #expanded-item="{ item }">
                <td colspan="7" @click="onClickHealthRoomDetails(item)" elevation="0" style="height:20px;background-color: #eee;">
                </td>
              </template>
            </v-data-table>            
          </v-tab-item>
          <v-tab-item value="contact" eager>
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog1 = true" small>
                  <v-icon right dark v-if="isFilterFlg1">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg1">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue1"
                  label="ワード検索"
                  placeholder="ワード検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewUnreadOnly1"
                  @click="changeFilter1(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewFavoriteOnly1"
                  @click="changeFilter1(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="contacts"
              :items-per-page="-1"
              ref="contactTable"
              dense
              disable-sort
              hide-default-footer
              group-by="groupName"
              @click:row="onClickContactDetails"
              :item-class="rowStyleContact"
              :loading="isContactsLoding" loading-text="読込中"
              :search="titleFilterValue1"
              :custom-filter="titleFilter1"
              mobile-breakpoint="0"
              item-key="contactId"
            >
              <template #[`group.header`]="{ group, headers, toggle, isOpen, items}">
                <td @click="toggle" :colspan="headers.length">
                  <v-btn icon>
                    <v-icon v-if="isOpen">mdi-minus</v-icon>
                    <v-icon v-else>mdi-plus</v-icon>
                  </v-btn>
                  {{ group }}（件数：{{ getCount(items, group) }} 未読：{{ getUnreadCount(items, group) }}）
                </td>
              </template>

              <!-- タイトルとモバイル用まとめ -->
              <template #[`item.title`]="{ item }">
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="text-caption">
                        {{ item.groupName }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(連絡タイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況) -->
                    <v-row no-gutters>
                      <v-col cols="9" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="3" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(対象ユーザー区分＋コメント＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.studentFlg">$student</v-icon>
                        <v-icon v-if="item.parentFlg">$parent</v-icon>
                        <div v-if="item.targetSelectFlg">【限定】</div>
                        <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                        <v-icon v-if="item.unreadCommentCount > 0" x-small color="red">mdi-new-box</v-icon>
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 投稿日と投稿者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <template #[`item.target`]="{ item }">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </template>
              <!-- コメントフラグ -->
              <template #[`item.commentFlg`]="{ item }">
                <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                <v-icon v-if="item.unreadCommentCount > 0" large color="red">mdi-new-box</v-icon>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <!-- 既読/未読 -->
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.unreadState }}
              </template>
              <!-- 本文 -->
              <template #expanded-item="{ item }">
                <td colspan="7" @click="onClickContactDetails(item)" elevation="0" style="height:20px;background-color: #eee;">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-truncate text-caption" style="width:200px">
                      <v-icon small>mdi-arrow-right-bottom</v-icon>
                      {{ item.body }}
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="survey" eager>
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog2 = true" small>
                  <v-icon right dark v-if="isFilterFlg2">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg2">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue2"
                  label="ワード検索"
                  placeholder="ワード検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewUnreadOnly2"
                  @click="changeFilter2(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewFavoriteOnly2"
                  @click="changeFilter2(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="surveyheaders"
              :items="surveylist"
              :items-per-page="-1"
              ref="surveyTable"
              dense
              disable-sort
              hide-default-footer
              group-by="groupName"
              @click:row="onClickSurveyDetails"
              :item-class="rowStyleSurvey"
              :loading="isSurveysLoding" loading-text="読込中"
              :search="titleFilterValue2"
              :custom-filter="titleFilter2"
              mobile-breakpoint="0"
              item-key="surveyId"
            >
              <template #[`group.header`]="{ group, headers, toggle, isOpen, items}">
                <td @click="toggle" :colspan="headers.length">
                  <v-btn icon>
                    <v-icon v-if="isOpen">mdi-minus</v-icon>
                    <v-icon v-else>mdi-plus</v-icon>
                  </v-btn>
                  {{ group }}（件数：{{ getCount(items, group) }} 未読：{{ getUnreadCount(items, group) }}）
                </td>
              </template>

              <!-- タイトルと締切日とモバイル用まとめ -->
              <template #[`item.title`]="{ item }">
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center" class="red--text" v-if="item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                  <v-col cols="12" justify="center" v-if="!item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                </v-row>

                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        {{ item.groupName }}：{{ item.targetUser }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(アンケートタイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況＋入力状況) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}：
                        {{ item.inputState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(回答期限＋対象ユーザー区分＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        期限:<span v-if="item.limitOverFlg" class="red--text">{{ item.limitDate }}</span>
                        <span v-if="!item.limitOverFlg">{{ item.limitDate }}</span>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.studentFlg">$student</v-icon>
                        <v-icon v-if="item.parentFlg">$parent</v-icon>
                        <div v-if="item.targetSelectFlg">【限定】</div>
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              
              <!-- 投稿日と投稿者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <!-- 対象ユーザー区分 -->
              <template #[`item.target`]="{ item }">
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.targetUser }}</v-col></v-row>
                <v-row no-gutters><v-col cols="12" justify="center">
                  <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                  <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                  <div v-if="item.targetSelectFlg">【限定】</div>
                </v-col></v-row>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <!-- 入力状況 -->
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.inputState }}
              </template>
              <!-- 本文 -->
              <template #expanded-item="{ item }">
                <td colspan="7" @click="onClickSurveyDetails(item)" elevation="0" style="height:20px;background-color: #eee;">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-truncate text-caption" style="width:200px">
                      <v-icon small>mdi-arrow-right-bottom</v-icon>
                      {{ item.body }}
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="talkRoom">
            <v-data-table
              :headers="talkRoomHeader"
              :items="talkRoomList"
              :items-per-page="-1"
              ref="talkRoom"
              dense
              disable-sort
              hide-default-footer
              @click:row="onClickTalkRoom"
              :loading="isTalkRoomLoding" loading-text="読込中"
              mobile-breakpoint="0"
            >
              <!-- 更新日と更新者とモバイル用まとめ -->
              <template #[`item.lastMessage`]="{ item }">
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.lastMessageDate }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center" v-if="!item.lastMessageInhouseFlg">{{ item.lastMessageUserNm }}</v-col>
                  <v-col cols="12" justify="center" v-if="item.lastMessageInhouseFlg" class="red--text">{{ item.lastMessageUserNm }}</v-col>
                </v-row>
                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(最終メッセージ日付) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="d-flex text-caption">
                        更新：{{ item.lastMessageDate }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption" v-if="!item.lastMessageInhouseFlg">{{ item.lastMessageUserNm }}</v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption red--text" v-if="item.lastMessageInhouseFlg">{{ item.lastMessageUserNm }}</v-col>
                    </v-row>
                    <!-- ２行目(連絡宛先) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-2">
                        {{ item.studentNm }}の保護者様
                      </v-col>
                    </v-row>
                    <!-- ３行目(教員名＋未読件数) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="d-flex text-caption">
                        {{ item.teacherNms }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end">
                        <v-btn color="red" x-small icon outlined v-if="item.unreadFlg">{{ item.unread }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 未読件数 -->
              <template #[`item.unreadFlg`]="{ item }">
                <v-btn color="red" small icon outlined v-if="item.unreadFlg">{{ item.unread }}</v-btn>
              </template>

            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- 左下読み込みボタン -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 保健室連絡フィルタモーダル -->
    <v-dialog
      v-model="healthFilterDialog"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearHealthRoomFilter" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doHealthRoomFilter" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="healthTitleFilterValue"
                label="ワード検索"
                placeholder="ワード検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="healthViewUnreadOnly"
                @click="healthChangeFilter(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- 右下成績ボタン -->
    <v-tooltip top v-if="seisekiExistsFlg">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom right color="orange" @click="seisekiModalFlg = true" style="bottom: 50px">
          <v-icon color="white">mdi-file-document-multiple</v-icon>
        </v-btn>
      </template>
      <span>成績を確認する。</span>
    </v-tooltip>

    <!-- 連絡フィルタモーダル -->
    <v-dialog
      v-model="filterDialog1"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter1" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter1" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue1"
                label="ワード検索"
                placeholder="ワード検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly1"
                @click="changeFilter1(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly1"
                @click="changeFilter1(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- アンケートフィルタモーダル -->
    <v-dialog
      v-model="filterDialog2"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter2" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter2" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue2"
                label="ワード検索"
                placeholder="ワード検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly2"
                @click="changeFilter2(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly2"
                @click="changeFilter2(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- 成績帳票確認モーダル -->
    <v-dialog
      v-model="seisekiModalFlg"
      persistent
      max-width="600"
    >
      <div>
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="600"
          color="#fff"
          v-if="seisekiModalMode == false"
        >
          <v-card-subtitle class="d-flex justify-center" white-space="pre">
            <v-row dense>
              <v-col dense cols="4" justify="center">
                <v-btn color="grey" class="black--text" @click="seisekiModalFlg = false" width="90%">
                  閉じる
                </v-btn>
              </v-col>
              <v-col dense cols="4" class="d-flex justify-center">
                <p>成績帳票を確認する</p>
              </v-col>
              <v-col dense cols="4" justify="center">
                <v-btn color="green" class="black--text" @click="seisekiModalMode = true" width="90%">
                  試験結果を確認する
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-data-table
              :headers="seisekiReportHeader"
              :items="seisekiReportList"
              :items-per-page="-1"
              dense
              disable-sort
              hide-default-footer
            >
              <template #[`item.action`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="onClickDwonload(item)">
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>帳票をダウンロードする。</span>
                  </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="600"
          color="#fff"
          v-if="seisekiModalMode == true"
        >
          <v-card-subtitle class="d-flex justify-center" white-space="pre">
            <v-row dense>
              <v-col dense cols="4" justify="center">
                <v-btn color="grey" class="black--text" @click="seisekiModalFlg = false" width="90%">
                  閉じる
                </v-btn>
              </v-col>
              <v-col dense cols="4" class="d-flex justify-center">
                <p>試験結果を確認する</p>
              </v-col>
              <v-col dense cols="4" justify="center">
                <v-btn color="green" class="black--text" @click="seisekiModalMode = false" width="90%">
                  通知表を確認する
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-data-table
              :headers="seisekiGradeHeader"
              :items="seisekiGradeList"
              :items-per-page="-1"
              dense
              disable-sort
              hide-default-footer
            >
              <template #[`item.action`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="viewGrade(item)">
                        <v-icon color="black">mdi-file-document-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>試験結果を確認する。</span>
                  </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>

    <!-- 試験結果表示モーダル -->
    <v-dialog
      v-model="viewGradeDialog"
      max-width="900"
      persistent
    >
      <div>
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="900"
          color="#fff"
        >
          <v-card-subtitle class="d-flex justify-center" white-space="pre">
            <v-row dense>
              <v-col dense cols="4" justify="center">
                <v-btn color="grey" class="black--text" @click="viewGradeDialog = false" width="90%">
                  閉じる
                </v-btn>
              </v-col>
              <v-col dense cols="4" class="d-flex justify-center">
                <p>試験結果確認</p>
              </v-col>
              <v-col dense cols="4" justify="center">
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-row v-if="kamokuNm1 != null" dense><v-col>科目名：{{ kamokuNm1 }}</v-col><v-col>点数：{{ seiseki1 }}</v-col><v-col v-if="kamokuAve1">科目平均：{{ kamokuAve1 }}</v-col><v-col v-if="deviation1">偏差値：{{ deviation1 }}</v-col><v-col v-if="rank1">順位：{{ rank1 }}</v-col></v-row>
            <v-row v-if="kamokuNm2 != null" dense><v-col>科目名：{{ kamokuNm2 }}</v-col><v-col>点数：{{ seiseki2 }}</v-col><v-col v-if="kamokuAve2">科目平均：{{ kamokuAve2 }}</v-col><v-col v-if="deviation2">偏差値：{{ deviation2 }}</v-col><v-col v-if="rank2">順位：{{ rank2 }}</v-col></v-row>
            <v-row v-if="kamokuNm3 != null" dense><v-col>科目名：{{ kamokuNm3 }}</v-col><v-col>点数：{{ seiseki3 }}</v-col><v-col v-if="kamokuAve3">科目平均：{{ kamokuAve3 }}</v-col><v-col v-if="deviation3">偏差値：{{ deviation3 }}</v-col><v-col v-if="rank3">順位：{{ rank3 }}</v-col></v-row>
            <v-row v-if="kamokuNm4 != null" dense><v-col>科目名：{{ kamokuNm4 }}</v-col><v-col>点数：{{ seiseki4 }}</v-col><v-col v-if="kamokuAve4">科目平均：{{ kamokuAve4 }}</v-col><v-col v-if="deviation4">偏差値：{{ deviation4 }}</v-col><v-col v-if="rank4">順位：{{ rank4 }}</v-col></v-row>
            <v-row v-if="kamokuNm5 != null" dense><v-col>科目名：{{ kamokuNm5 }}</v-col><v-col>点数：{{ seiseki5 }}</v-col><v-col v-if="kamokuAve5">科目平均：{{ kamokuAve5 }}</v-col><v-col v-if="deviation5">偏差値：{{ deviation5 }}</v-col><v-col v-if="rank5">順位：{{ rank5 }}</v-col></v-row>
            <v-row v-if="kamokuNm6 != null" dense><v-col>科目名：{{ kamokuNm6 }}</v-col><v-col>点数：{{ seiseki6 }}</v-col><v-col v-if="kamokuAve6">科目平均：{{ kamokuAve6 }}</v-col><v-col v-if="deviation6">偏差値：{{ deviation6 }}</v-col><v-col v-if="rank6">順位：{{ rank6 }}</v-col></v-row>
            <v-row v-if="kamokuNm7 != null" dense><v-col>科目名：{{ kamokuNm7 }}</v-col><v-col>点数：{{ seiseki7 }}</v-col><v-col v-if="kamokuAve7">科目平均：{{ kamokuAve7 }}</v-col><v-col v-if="deviation7">偏差値：{{ deviation7 }}</v-col><v-col v-if="rank7">順位：{{ rank7 }}</v-col></v-row>
            <v-row v-if="kamokuNm8 != null" dense><v-col>科目名：{{ kamokuNm8 }}</v-col><v-col>点数：{{ seiseki8 }}</v-col><v-col v-if="kamokuAve8">科目平均：{{ kamokuAve8 }}</v-col><v-col v-if="deviation8">偏差値：{{ deviation8 }}</v-col><v-col v-if="rank8">順位：{{ rank8 }}</v-col></v-row>
            <v-row v-if="kamokuNm9 != null" dense><v-col>科目名：{{ kamokuNm9 }}</v-col><v-col>点数：{{ seiseki9 }}</v-col><v-col v-if="kamokuAve9">科目平均：{{ kamokuAve9 }}</v-col><v-col v-if="deviation9">偏差値：{{ deviation9 }}</v-col><v-col v-if="rank9">順位：{{ rank9 }}</v-col></v-row>
            <v-row v-if="kamokuNm10 != null" dense><v-col>科目名：{{ kamokuNm10 }}</v-col><v-col>点数：{{ seiseki10 }}</v-col><v-col v-if="kamokuAve10">科目平均：{{ kamokuAve10 }}</v-col><v-col v-if="deviation10">偏差値：{{ deviation10 }}</v-col><v-col v-if="rank10">順位：{{ rank10 }}</v-col></v-row>
            <v-row v-if="kamokuNm11 != null" dense><v-col>科目名：{{ kamokuNm11 }}</v-col><v-col>点数：{{ seiseki11 }}</v-col><v-col v-if="kamokuAve11">科目平均：{{ kamokuAve11 }}</v-col><v-col v-if="deviation11">偏差値：{{ deviation11 }}</v-col><v-col v-if="rank11">順位：{{ rank11 }}</v-col></v-row>
            <v-row v-if="kamokuNm12 != null" dense><v-col>科目名：{{ kamokuNm12 }}</v-col><v-col>点数：{{ seiseki12 }}</v-col><v-col v-if="kamokuAve12">科目平均：{{ kamokuAve12 }}</v-col><v-col v-if="deviation12">偏差値：{{ deviation12 }}</v-col><v-col v-if="rank12">順位：{{ rank12 }}</v-col></v-row>
            <v-row v-if="kamokuNm13 != null" dense><v-col>科目名：{{ kamokuNm13 }}</v-col><v-col>点数：{{ seiseki13 }}</v-col><v-col v-if="kamokuAve13">科目平均：{{ kamokuAve13 }}</v-col><v-col v-if="deviation13">偏差値：{{ deviation13 }}</v-col><v-col v-if="rank13">順位：{{ rank13 }}</v-col></v-row>
            <v-row v-if="kamokuNm14 != null" dense><v-col>科目名：{{ kamokuNm14 }}</v-col><v-col>点数：{{ seiseki14 }}</v-col><v-col v-if="kamokuAve14">科目平均：{{ kamokuAve14 }}</v-col><v-col v-if="deviation14">偏差値：{{ deviation14 }}</v-col><v-col v-if="rank14">順位：{{ rank14 }}</v-col></v-row>
            <v-row v-if="kamokuNm15 != null" dense><v-col>科目名：{{ kamokuNm15 }}</v-col><v-col>点数：{{ seiseki15 }}</v-col><v-col v-if="kamokuAve15">科目平均：{{ kamokuAve15 }}</v-col><v-col v-if="deviation15">偏差値：{{ deviation15 }}</v-col><v-col v-if="rank15">順位：{{ rank15 }}</v-col></v-row>
            <v-row v-if="kamokuNm16 != null" dense><v-col>科目名：{{ kamokuNm16 }}</v-col><v-col>点数：{{ seiseki16 }}</v-col><v-col v-if="kamokuAve16">科目平均：{{ kamokuAve16 }}</v-col><v-col v-if="deviation16">偏差値：{{ deviation16 }}</v-col><v-col v-if="rank16">順位：{{ rank16 }}</v-col></v-row>
            <v-row v-if="kamokuNm17 != null" dense><v-col>科目名：{{ kamokuNm17 }}</v-col><v-col>点数：{{ seiseki17 }}</v-col><v-col v-if="kamokuAve17">科目平均：{{ kamokuAve17 }}</v-col><v-col v-if="deviation17">偏差値：{{ deviation17 }}</v-col><v-col v-if="rank17">順位：{{ rank17 }}</v-col></v-row>
            <v-row v-if="kamokuNm18 != null" dense><v-col>科目名：{{ kamokuNm18 }}</v-col><v-col>点数：{{ seiseki18 }}</v-col><v-col v-if="kamokuAve18">科目平均：{{ kamokuAve18 }}</v-col><v-col v-if="deviation18">偏差値：{{ deviation18 }}</v-col><v-col v-if="rank18">順位：{{ rank18 }}</v-col></v-row>
            <v-row v-if="kamokuNm19 != null" dense><v-col>科目名：{{ kamokuNm19 }}</v-col><v-col>点数：{{ seiseki19 }}</v-col><v-col v-if="kamokuAve19">科目平均：{{ kamokuAve19 }}</v-col><v-col v-if="deviation19">偏差値：{{ deviation19 }}</v-col><v-col v-if="rank19">順位：{{ rank19 }}</v-col></v-row>
            <v-row v-if="kamokuNm20 != null" dense><v-col>科目名：{{ kamokuNm20 }}</v-col><v-col>点数：{{ seiseki20 }}</v-col><v-col v-if="kamokuAve20">科目平均：{{ kamokuAve20 }}</v-col><v-col v-if="deviation20">偏差値：{{ deviation20 }}</v-col><v-col v-if="rank20">順位：{{ rank20 }}</v-col></v-row>
            <v-row v-if="kamokuNm21 != null" dense><v-col>科目名：{{ kamokuNm21 }}</v-col><v-col>点数：{{ seiseki21 }}</v-col><v-col v-if="kamokuAve21">科目平均：{{ kamokuAve21 }}</v-col><v-col v-if="deviation21">偏差値：{{ deviation21 }}</v-col><v-col v-if="rank21">順位：{{ rank21 }}</v-col></v-row>
            <v-row v-if="kamokuNm22 != null" dense><v-col>科目名：{{ kamokuNm22 }}</v-col><v-col>点数：{{ seiseki22 }}</v-col><v-col v-if="kamokuAve22">科目平均：{{ kamokuAve22 }}</v-col><v-col v-if="deviation22">偏差値：{{ deviation22 }}</v-col><v-col v-if="rank22">順位：{{ rank22 }}</v-col></v-row>
            <v-row v-if="kamokuNm23 != null" dense><v-col>科目名：{{ kamokuNm23 }}</v-col><v-col>点数：{{ seiseki23 }}</v-col><v-col v-if="kamokuAve23">科目平均：{{ kamokuAve23 }}</v-col><v-col v-if="deviation23">偏差値：{{ deviation23 }}</v-col><v-col v-if="rank23">順位：{{ rank23 }}</v-col></v-row>
            <v-row v-if="kamokuNm24 != null" dense><v-col>科目名：{{ kamokuNm24 }}</v-col><v-col>点数：{{ seiseki24 }}</v-col><v-col v-if="kamokuAve24">科目平均：{{ kamokuAve24 }}</v-col><v-col v-if="deviation24">偏差値：{{ deviation24 }}</v-col><v-col v-if="rank24">順位：{{ rank24 }}</v-col></v-row>
            <v-row v-if="kamokuNm25 != null" dense><v-col>科目名：{{ kamokuNm25 }}</v-col><v-col>点数：{{ seiseki25 }}</v-col><v-col v-if="kamokuAve25">科目平均：{{ kamokuAve25 }}</v-col><v-col v-if="deviation25">偏差値：{{ deviation25 }}</v-col><v-col v-if="rank25">順位：{{ rank25 }}</v-col></v-row>
            <v-row v-if="kamokuNm26 != null" dense><v-col>科目名：{{ kamokuNm26 }}</v-col><v-col>点数：{{ seiseki26 }}</v-col><v-col v-if="kamokuAve26">科目平均：{{ kamokuAve26 }}</v-col><v-col v-if="deviation26">偏差値：{{ deviation26 }}</v-col><v-col v-if="rank26">順位：{{ rank26 }}</v-col></v-row>
            <v-row v-if="kamokuNm27 != null" dense><v-col>科目名：{{ kamokuNm27 }}</v-col><v-col>点数：{{ seiseki27 }}</v-col><v-col v-if="kamokuAve27">科目平均：{{ kamokuAve27 }}</v-col><v-col v-if="deviation27">偏差値：{{ deviation27 }}</v-col><v-col v-if="rank27">順位：{{ rank27 }}</v-col></v-row>
            <v-row v-if="kamokuNm28 != null" dense><v-col>科目名：{{ kamokuNm28 }}</v-col><v-col>点数：{{ seiseki28 }}</v-col><v-col v-if="kamokuAve28">科目平均：{{ kamokuAve28 }}</v-col><v-col v-if="deviation28">偏差値：{{ deviation28 }}</v-col><v-col v-if="rank28">順位：{{ rank28 }}</v-col></v-row>
            <v-row v-if="kamokuNm29 != null" dense><v-col>科目名：{{ kamokuNm29 }}</v-col><v-col>点数：{{ seiseki29 }}</v-col><v-col v-if="kamokuAve29">科目平均：{{ kamokuAve29 }}</v-col><v-col v-if="deviation29">偏差値：{{ deviation29 }}</v-col><v-col v-if="rank29">順位：{{ rank29 }}</v-col></v-row>
            <v-row v-if="kamokuNm30 != null" dense><v-col>科目名：{{ kamokuNm30 }}</v-col><v-col>点数：{{ seiseki30 }}</v-col><v-col v-if="kamokuAve30">科目平均：{{ kamokuAve30 }}</v-col><v-col v-if="deviation30">偏差値：{{ deviation30 }}</v-col><v-col v-if="rank30">順位：{{ rank30 }}</v-col></v-row>
            <v-row v-if="kamokuNm31 != null" dense><v-col>科目名：{{ kamokuNm31 }}</v-col><v-col>点数：{{ seiseki31 }}</v-col><v-col v-if="kamokuAve31">科目平均：{{ kamokuAve31 }}</v-col><v-col v-if="deviation31">偏差値：{{ deviation31 }}</v-col><v-col v-if="rank31">順位：{{ rank31 }}</v-col></v-row>
            <v-row v-if="kamokuNm32 != null" dense><v-col>科目名：{{ kamokuNm32 }}</v-col><v-col>点数：{{ seiseki32 }}</v-col><v-col v-if="kamokuAve32">科目平均：{{ kamokuAve32 }}</v-col><v-col v-if="deviation32">偏差値：{{ deviation32 }}</v-col><v-col v-if="rank32">順位：{{ rank32 }}</v-col></v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
import { saveAsCommon } from '../../common';
export default {
  data() {
    return {
      // 早退明細表示用
      isViewSotai: false,
      sotaiheaders : [
        {text:"氏名", value: "name", class: "hidden-xs-only", width: "13%" },
        {text:"早退事由", value: "leavingReason", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        {text:"下校開始予定時刻", value: "leavingTime", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        {text:"早退時刻", value: "gatePassageTime", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        {text:"", value: "actions", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        {text:"帰宅連絡状況", value: "arrivalStatusFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        {text:"", value: "actions2", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
      ],
      sotailist : [],

      tab: 'contact',
      // 保健室連絡用
      healthFilterDialog: false,
      healthFilterFlg: false,
      healthViewUnreadOnly: false,
      healthTitleFilterValue: "",
      // 連絡
      filterDialog1: false,
      isFilterFlg1: false,
      titleFilterValue1: "",
      viewUnreadOnly1: false,
      viewFavoriteOnly1: false,
      // アンケート
      filterDialog2: false,
      isFilterFlg2: false,
      titleFilterValue2: "",
      viewUnreadOnly2: false,
      viewFavoriteOnly2: false,
      // 個別連絡(フィルター無し)

      headers: [
        { text: "タイトル", value: "title", class: "hidden-xs-only" },
        { text: "グループ名", value: "groupName", class: "hidden-xs-only", cellClass: "hidden-xs-only"},
        { text: "対象", value: "target", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "更新日時/更新者", value: "openDateAndPostUser", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "コメント", value: "commentFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter1 },
        { text: "既読/未読", value: "unreadFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter1 },
        { value: "contactId", class: "d-none", cellClass: "d-none" },
        { value: "body", class: "d-none", cellClass: "d-none" },
        { value: "data-table-expand", class: "d-none", cellClass: "d-none" },
      ],
      // 保健室からの連絡
      healthRoomList : [],
      healthRoomheaders: [
        { text: "タイトル", value:"mailTitle", class: "hidden-xs-only" },
        { text: "来室日時", value:"postDate", class: "hidden-xs-only" },
        { text: "更新日時/更新者", value:"openDateAndPostUser", class: "hidden-xs-only" },
        { text: "既読/未読", value:"unreadFlg", class: "hidden-xs-only", filter: this.unreadHealthRoomFilter },
        { value: "studentId", class: "d-none", cellClass: "d-none" },
        { value: "studentNm", class: "d-none", cellClass: "d-none" },
        { value: "shochi", class: "d-none", cellClass: "d-none" },
        { value: "shoujo", class: "d-none", cellClass: "d-none" },
        { value: "comment", class: "d-none", cellClass: "d-none" },
        { value: "updDate", class: "d-none", cellClass: "d-none" },
        { value: "unreadFlg", class: "d-none", cellClass: "d-none", },
        { value: "raishitsuId", class: "d-none", cellClass: "d-none", },
        { value: "userNm", class: "d-none", cellClass: "d-none" },
      ],
      contacts: [],
      surveyheaders : [
        { text: "タイトル/回答期限", value: "title", class: "hidden-xs-only" },
        { text: "グループ名", value: "groupName", class: "hidden-xs-only", cellClass: "hidden-xs-only"},
        { text: "対象", value: "target", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "更新日時/更新者", value: "openDateAndPostUser", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter2 },
        { text: "既読/未読", value: "unreadState", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "入力状況", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter2 },
        { value: "surveyId", class: "d-none", cellClass: "d-none" },
        { value: "body", class: "d-none", cellClass: "d-none" },
        { value: "data-table-expand", class: "d-none", cellClass: "d-none" },
      ],
      surveylist : [],
      talkRoomHeader1 : [
        { text: "更新日時/更新者", value: "lastMessage", class: "hidden-xs-only", width: "300" },
        { text: "生徒名", value: "studentNm", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "教員", value: "teacherNms", class: "hidden-xs-only", cellClass: "hidden-xs-only" , width: "200" }, 
        { text: "未読件数", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", width: "100" }, 
      ],
      talkRoomHeader2 : [
        { text: "更新日時/更新者", value: "lastMessage", class: "hidden-xs-only", width: "300" },
        { text: "教員", value: "teacherNms", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "200" }, 
        { text: "未読件数", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", width: "100" }, 
      ],
      unreadFlg: false,
      userKbn: "",
      userNm: "",
      talkRoomList : [],
      isHealthRoomLoding : false,
      isContactsLoding: false,
      isSurveysLoding: false,
      isTalkRoomLoding: false,
      mailAddressAuthFlg: true,
      processingFlg: false,
      unreadHealthRoomCount: 0,
      unreadContactCount: 0,
      unreadSurveysCount: 0,
      unreadTalkRoomCount: 0,
      isTargetViewFlg: false,
      seisekiExistsFlg: false,
      seisekiModalFlg: false,
      seisekiModalMode: false,
      seisekiReportHeader: [
        { text: "年度", value: "nendo", class: "hidden-xs-only" },
        { text: "学期", value: "gakki", class: "hidden-xs-only" },
        { text: "氏名", value: "userNm", class: "hidden-xs-only" },
        { text: "ダウンロード", value: "action", class: "hidden-xs-only" },
      ],
      seisekiReportList: [],
      seisekiGradeHeader: [
        { text: "氏名", value: "userNm", class: "hidden-xs-only" },
        { text: "試験名", value: "sikenNm", class: "hidden-xs-only" },
        { text: "表示", value: "action", class: "hidden-xs-only" },
      ],
      seisekiGradeList: [],

      // 成績表示モーダル用
      viewGradeDialog: false,
      studentConfirmFlg: false,
      studentConfirmDate: "",
      kamokuNm1:null,
      kamokuNm2:null,
      kamokuNm3:null,
      kamokuNm4:null,
      kamokuNm5:null,
      kamokuNm6:null,
      kamokuNm7:null,
      kamokuNm8:null,
      kamokuNm9:null,
      kamokuNm10:null,
      kamokuNm11:null,
      kamokuNm12:null,
      kamokuNm13:null,
      kamokuNm14:null,
      kamokuNm15:null,
      kamokuNm16:null,
      kamokuNm17:null,
      kamokuNm18:null,
      kamokuNm19:null,
      kamokuNm20:null,
      kamokuNm21:null,
      kamokuNm22:null,
      kamokuNm23:null,
      kamokuNm24:null,
      kamokuNm25:null,
      kamokuNm26:null,
      kamokuNm27:null,
      kamokuNm28:null,
      kamokuNm29:null,
      kamokuNm30:null,
      kamokuNm31:null,
      kamokuNm32:null,
      seiseki1:null,
      seiseki2:null,
      seiseki3:null,
      seiseki4:null,
      seiseki5:null,
      seiseki6:null,
      seiseki7:null,
      seiseki8:null,
      seiseki9:null,
      seiseki10:null,
      seiseki11:null,
      seiseki12:null,
      seiseki13:null,
      seiseki14:null,
      seiseki15:null,
      seiseki16:null,
      seiseki17:null,
      seiseki18:null,
      seiseki19:null,
      seiseki20:null,
      seiseki21:null,
      seiseki22:null,
      seiseki23:null,
      seiseki24:null,
      seiseki25:null,
      seiseki26:null,
      seiseki27:null,
      seiseki28:null,
      seiseki29:null,
      seiseki30:null,
      seiseki31:null,
      seiseki32:null,
      kamokuAve1:null,
      kamokuAve2:null,
      kamokuAve3:null,
      kamokuAve4:null,
      kamokuAve5:null,
      kamokuAve6:null,
      kamokuAve7:null,
      kamokuAve8:null,
      kamokuAve9:null,
      kamokuAve10:null,
      kamokuAve11:null,
      kamokuAve12:null,
      kamokuAve13:null,
      kamokuAve14:null,
      kamokuAve15:null,
      kamokuAve16:null,
      kamokuAve17:null,
      kamokuAve18:null,
      kamokuAve19:null,
      kamokuAve20:null,
      kamokuAve21:null,
      kamokuAve22:null,
      kamokuAve23:null,
      kamokuAve24:null,
      kamokuAve25:null,
      kamokuAve26:null,
      kamokuAve27:null,
      kamokuAve28:null,
      kamokuAve29:null,
      kamokuAve30:null,
      kamokuAve31:null,
      kamokuAve32:null,
      deviation1:null,
      deviation2:null,
      deviation3:null,
      deviation4:null,
      deviation5:null,
      deviation6:null,
      deviation7:null,
      deviation8:null,
      deviation9:null,
      deviation10:null,
      deviation11:null,
      deviation12:null,
      deviation13:null,
      deviation14:null,
      deviation15:null,
      deviation16:null,
      deviation17:null,
      deviation18:null,
      deviation19:null,
      deviation20:null,
      deviation21:null,
      deviation22:null,
      deviation23:null,
      deviation24:null,
      deviation25:null,
      deviation26:null,
      deviation27:null,
      deviation28:null,
      deviation29:null,
      deviation30:null,
      deviation31:null,
      deviation32:null,
      rank1:null,
      rank2:null,
      rank3:null,
      rank4:null,
      rank5:null,
      rank6:null,
      rank7:null,
      rank8:null,
      rank9:null,
      rank10:null,
      rank11:null,
      rank12:null,
      rank13:null,
      rank14:null,
      rank15:null,
      rank16:null,
      rank17:null,
      rank18:null,
      rank19:null,
      rank20:null,
      rank21:null,
      rank22:null,
      rank23:null,
      rank24:null,
      rank25:null,
      rank26:null,
      rank27:null,
      rank28:null,
      rank29:null,
      rank30:null,
      rank31:null,
      rank32:null,
      totalSeiseki:null,
      seisekiAve:null,
      deviationAve:null,
      classRank:null,
      classDenominator:null,
      gakunenRank:null,
      gakunenDenominator:null,
      courseRank:null,
      courseDenominator:null,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    console.log(this.$refs);
    this.tab = this.$store.state.viewConditionTabSelect;
    this.isTargetViewFlg = (this.$root.students.length > 1);
    this.seisekiLoad();
    await this.reload();
  },
  watch: {
    tab: function(newVal) {this.$store.dispatch('setViewConditionTabSelect', newVal)},
  },
  computed: {
    talkRoomHeader () {
      if(this.$root.students.length > 1){
        return this.talkRoomHeader1;
      } else {
        return this.talkRoomHeader2;
      }
    }
  },
  methods: {
    // タイトルフィルタ
    titleFilter(value) {
      if (!this.titleFilterValue) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue);
    },
    // 未読フィルタ
    unreadFilter(value) {
      if (!this.viewUnreadOnly) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter(value) {
      if (!this.viewFavoriteOnly) {
        return true;
      }
      return value;
    },
    changeFilter(flg){
      if(this.viewUnreadOnly && this.viewFavoriteOnly){
        if(flg){
          this.viewFavoriteOnly = false;
        } else {
          this.viewUnreadOnly = false;
        }
      }
    },
    // 保健室連絡詳細
    onClickHealthRoomDetails(item) {
      this.$router.push({
        name: "ContactHealthRoom",
        params: {
          studentId : item.studentId,
          mailTitle : item.mailTitle,
          shochi: item.shoujo,
          shoujo: item.shochi,
          comment: item.comment,
          unreadFlg: item.unreadFlg,
          raishitsuId: item.raishitsuId,
          userNm: item.userNm,
        }
      });

    },
    // 連絡詳細
    onClickContactDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.contactTable.$children[0].filteredItems;
      // 連絡IDのみ抽出
      var contactListBuff = Enumerable.from(items).where(i => i.groupName == item.groupName).select(x => { return x.messageId }).toArray();
      var contactListIndex = contactListBuff.indexOf(item.messageId);

      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId : item.messageId,
          contactListBuff: contactListBuff,
          contactListIndex: contactListIndex,
        }
      });
    },
    // 早退返信
    onClickContactSotai(item) {
      this.$router.push({
        name: "LeavingReply",
        params: {
          id: item.studentId,
        } 
      });
    },
    // アンケート回答
    onClickSurveyDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.surveyTable.$children[0].filteredItems;
      // 調査IDのみ抽出
      var surveyListBuff = Enumerable.from(items).where(i => i.groupName == item.groupName).select(x => { return x.responseId }).toArray();
      var surveyListIndex = surveyListBuff.indexOf(item.responseId);

      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : item.responseId,
          surveyListBuff: surveyListBuff,
          surveyListIndex: surveyListIndex,
        } 
      });
    },
    // 個別連絡
    onClickTalkRoom(item) {
      this.$router.push({
        name: "TalkRoom",
        params: {
          roomId : item.roomId,
        } 
      });
    },
    // 成績情報読込
    async seisekiLoad() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined({funcName:"loadSeisekiInfo"}).then((ret) => {
        this.seisekiExistsFlg = ret.data.existsFlg;
        this.seisekiReportList = ret.data.reportList;
        this.seisekiGradeList = ret.data.gradeList;
      });
    },
    // 成績帳票ダウンロード
    async onClickDwonload(item) {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName: "getSeisekiReportLink",
        reportId: item.reportId,
        repoCd: item.repoCd,
        userId: item.userId,
      }
      var retParam = {};
      await schoolAidHomeLogined(param).then((ret) => {
        retParam.url = ret.data.url;
        retParam.fileName = ret.data.fileName;
      });

      saveAsCommon(retParam.url, retParam.fileName);
    },
    // ページ再読込
    async reload() {
      var unreadHealthRoomCount = 0;
      var unreadContactCount = 0;
      var unreadSurveysCount = 0;
      var unreadTalkRoomCount = 0;
      // 学校からの連絡取得
      this.healthRoomList = [];
      this.contacts = [];
      this.sotailist = [];
      this.surveylist = [];
      this.talkRoomList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isHealthRoomLoding = true;
      this.isContactsLoding = true;
      this.isSurveysLoding = true;
      this.isTalkRoomLoding = true;
      // 保健室来室情報
      await schoolAidHomeLogined({funcName:"loadTopPageHealthRoom"}).then((snapshot) => {
        var ret = [];
        snapshot.data.forEach((doc) => {
          var row = {
            studentId : doc.studentId,
            studentNm : doc.studentNm,
            mailTitle: doc.mailTitle,
            shoujo: doc.shoujo,
            shochi : doc.shochi,
            comment: doc.comment,
            unreadFlg : doc.unreadFlg,
            raishitsuId : doc.raishitsuId,
            updDate : doc.updDate,
            postDate: doc.postDate,
            userKbn: doc.userKbn,
            userNm: doc.userNm,
          };
          if(row.unreadFlg){
            row.unreadState = "未読";
            unreadHealthRoomCount++;
          } else {
            row.unreadState = "既読";
          }
          console.log("テスト:" + doc.userNm);
          ret.push(row);
        });
        this.unreadHealthRoomCount = unreadHealthRoomCount;
        this.healthRoomList = ret;
        this.isHealthRoomLoding = false;
      });
      Object.keys(this.$refs.healthRoomTable.openCache).forEach(g => this.$refs.healthRoomTable.openCache[g] = false);
      await schoolAidHomeLogined({funcName:"loadTopPage"}).then((contacts) => {
        var ret = [];
        contacts.data.list.forEach((contact) => {
          var row = {
            contactId: contact.contactId,
            openDate: contact.openDate,
            postUser: contact.postUser,
            groupName: contact.groupName,
            studentFlg: contact.studentFlg,
            parentFlg: contact.parentFlg,
            teacherFlg: contact.teacherFlg,
            title: contact.title,
            body: contact.body,
            messageId: contact.messageId,
            commentFlg: contact.commentFlg,
            unreadFlg: (contact.unreadFlg)?contact.unreadFlg:(contact.unreadCommentCount>0),
            unreadCommentCount: contact.unreadCommentCount,
            favoriteFlg: contact.favoriteFlg,
            targetSelectFlg: contact.targetSelectFlg,
          };
          if(row.unreadFlg){
            row.unreadState = "未読";
            unreadContactCount++;
          } else {
            row.unreadState = "既読";
          }
          ret.push(row);
        });
        this.unreadContactCount = unreadContactCount;
        this.contacts = ret;
        this.mailAddressAuthFlg = contacts.data.mailAddressAuthFlg;
        this.isContactsLoding = false;
      });
      Object.keys(this.$refs.contactTable.openCache).forEach(g => this.$refs.contactTable.openCache[g] = false);
      await schoolAidHomeLogined({funcName:"loadTopPageSurvey"}).then((snapshot) => {
        var ret = [];
        snapshot.data.forEach((survey) => {
          var row = {
            surveyId: survey.surveyId,
            openDate: survey.openDate,
            limitDate: survey.limitDate,
            limitOverFlg: survey.limitOverFlg,
            postUser: survey.postUser,
            targetUser: survey.targetUser,
            groupName: survey.groupName,
            studentFlg: survey.studentFlg,
            parentFlg: survey.parentFlg,
            teacherFlg: survey.teacherFlg,
            title: survey.title,
            body: survey.body,
            responseId: survey.responseId,
            unreadFlg: survey.unreadFlg,
            inputFlg: survey.inputFlg,
            favoriteFlg: survey.favoriteFlg,
            targetSelectFlg: survey.targetSelectFlg,
          };
          if(survey.unreadFlg){
            row.unreadState = "未読";
            unreadSurveysCount++;
          } else {
            row.unreadState = "既読";
          }
          if(survey.inputFlg){
            row.inputState = "入力済";
          } else {
            row.inputState = "未入力";
          }
          ret.push(row);
        });
        this.unreadSurveysCount = unreadSurveysCount;
        this.surveylist = ret;
        this.isSurveysLoding = false;
      });
      Object.keys(this.$refs.surveyTable.openCache).forEach(g => this.$refs.surveyTable.openCache[g] = false);
      this.processingFlg = false;
      schoolAidHomeLogined({funcName:"loadTopPageSotai"}).then((snapshot) => {
        var ret = [];
        snapshot.data.forEach((doc) => {
          var row = {
            name : doc.name,
            leavingReason : doc.leavingReason,
            leavingTime : doc.leavingTime,
            gatePassageTime: doc.gatePassageTime,
            studentId : doc.studentId,
            processingFlg: doc.processingFlg,
            arrivalStatusFlg: doc.arrivalStatusFlg,
          };

          // 帰宅連絡ボタンの警告表示
          if(row.processingFlg == true){
            this.processingFlg = true;
          }

          ret.push(row);
        });
        this.sotailist = ret;
        // 早退確認の表示制御
        if(this.sotailist.length !== 0){
          this.isViewSotai = true;
        } else {
          this.isViewSotai = false;
        }
      });
      schoolAidHomeLogined({funcName:"loadTopPageTalkRoom"}).then((snapshot) => {
        var ret = [];
        snapshot.data.forEach((recode) => {
          var row = {
            roomId: recode.roomId,
            lastMessageDate: recode.lastMessageDate,
            lastMessageUserNm: recode.lastMessageUserNm,
            lastMessageInhouseFlg: recode.lastMessageInhouseFlg,
            title: recode.title,
            studentNm: recode.studentNm,
            targetParentFlg: recode.targetParentFlg,
            teacherNms: recode.teacherNms,
            lockFlg: recode.lockFlg,
            total: recode.total,
            read: recode.read,
            unread: (recode.total - recode.read),
            unreadFlg: (recode.total != recode.read),
          };
          // 未読件数の集計
          unreadTalkRoomCount = unreadTalkRoomCount + (recode.total - recode.read);
          ret.push(row);
        });
        this.unreadTalkRoomCount = unreadTalkRoomCount;
        this.talkRoomList = ret;
        this.isTalkRoomLoding = false;
      });
    },
    async onChangeArrivalStatus(item)  {
      var result = confirm("帰宅状況を学校へ連絡しますか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeArrivalStatus",
        studentId: item.studentId,
      };
      await schoolAidHomeLogined(data);
      await this.reload();
    },
    rowStyleHealthRoom: function (item) {
      return item.unreadFlg == true ? 'not-yet-style' : 'do-style'
    },
    rowStyleContact: function (item) {
      return item.unreadFlg == true ? 'not-yet-style' : 'do-style'
    },
    rowStyleSurvey: function (item) {
      return item.inputFlg == true ? 'do-style' : 'not-yet-style'
    },
    getCountHealthRoom: function (items, group) {
      var count = 0;
      for(let row of items){
        if(row.studentNm == group){
          count++;
        }
      }
      return count;
    },

    getUnreadCountHealthRoom: function (items, group) {
      var count = 0;
      for(let row of items){
        if(row.studentNm == group && row.unreadFlg == true){
          count++;
        }
      }
      return count;
    },
    getCount: function (items, group) {
      var count = 0;
      for(let row of items){
        if(row.groupName == group){
          count++;
        }
      }
      return count;
    },
    getUnreadCount: function (items, group) {   
      var count = 0;
      for(let row of items){
        if(row.groupName == group && row.unreadFlg == true){
          count++;
        }
      }
      return count;
    },

    // 検索(保健室来室用)=================================================================================
    // タイトルフィルタ
    healthRoomTitleFilter(value, search, item) {
      if(!search)return true;
      return (item.mailTitle.indexOf(search) >= 0);      
    },
    // 未読フィルタ
    unreadHealthRoomFilter(value) {
      if (!this.healthViewUnreadOnly) {
        return true;
      }
      return value;
    },
    healthChangeFilter(flg){
      if(this.healthViewUnreadOnly){
        if(!flg) {
          this.healthViewUnreadOnly = false;
        }
      }
    },

    // 試験結果の明細を選択された場合の表示処理
    async viewGrade(item){
      this.resetGrade();
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName: "readTestGrade",
        sikenCd: item.sikenCd,
        userId: item.userId,
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        if(ret.data != "success")return;
      });
      this.kamokuNm1=item.kamokuNm1;
      this.kamokuNm2=item.kamokuNm2;
      this.kamokuNm3=item.kamokuNm3;
      this.kamokuNm4=item.kamokuNm4;
      this.kamokuNm5=item.kamokuNm5;
      this.kamokuNm6=item.kamokuNm6;
      this.kamokuNm7=item.kamokuNm7;
      this.kamokuNm8=item.kamokuNm8;
      this.kamokuNm9=item.kamokuNm9;
      this.kamokuNm10=item.kamokuNm10;
      this.kamokuNm11=item.kamokuNm11;
      this.kamokuNm12=item.kamokuNm12;
      this.kamokuNm13=item.kamokuNm13;
      this.kamokuNm14=item.kamokuNm14;
      this.kamokuNm15=item.kamokuNm15;
      this.kamokuNm16=item.kamokuNm16;
      this.kamokuNm17=item.kamokuNm17;
      this.kamokuNm18=item.kamokuNm18;
      this.kamokuNm19=item.kamokuNm19;
      this.kamokuNm20=item.kamokuNm20;
      this.kamokuNm21=item.kamokuNm21;
      this.kamokuNm22=item.kamokuNm22;
      this.kamokuNm23=item.kamokuNm23;
      this.kamokuNm24=item.kamokuNm24;
      this.kamokuNm25=item.kamokuNm25;
      this.kamokuNm26=item.kamokuNm26;
      this.kamokuNm27=item.kamokuNm27;
      this.kamokuNm28=item.kamokuNm28;
      this.kamokuNm29=item.kamokuNm29;
      this.kamokuNm30=item.kamokuNm30;
      this.kamokuNm31=item.kamokuNm31;
      this.kamokuNm32=item.kamokuNm32;
      this.seiseki1=item.seiseki1;
      this.seiseki2=item.seiseki2;
      this.seiseki3=item.seiseki3;
      this.seiseki4=item.seiseki4;
      this.seiseki5=item.seiseki5;
      this.seiseki6=item.seiseki6;
      this.seiseki7=item.seiseki7;
      this.seiseki8=item.seiseki8;
      this.seiseki9=item.seiseki9;
      this.seiseki10=item.seiseki10;
      this.seiseki11=item.seiseki11;
      this.seiseki12=item.seiseki12;
      this.seiseki13=item.seiseki13;
      this.seiseki14=item.seiseki14;
      this.seiseki15=item.seiseki15;
      this.seiseki16=item.seiseki16;
      this.seiseki17=item.seiseki17;
      this.seiseki18=item.seiseki18;
      this.seiseki19=item.seiseki19;
      this.seiseki20=item.seiseki20;
      this.seiseki21=item.seiseki21;
      this.seiseki22=item.seiseki22;
      this.seiseki23=item.seiseki23;
      this.seiseki24=item.seiseki24;
      this.seiseki25=item.seiseki25;
      this.seiseki26=item.seiseki26;
      this.seiseki27=item.seiseki27;
      this.seiseki28=item.seiseki28;
      this.seiseki29=item.seiseki29;
      this.seiseki30=item.seiseki30;
      this.seiseki31=item.seiseki31;
      this.seiseki32=item.seiseki32;
      this.kamokuAve1=item.kamokuAve1;
      this.kamokuAve2=item.kamokuAve2;
      this.kamokuAve3=item.kamokuAve3;
      this.kamokuAve4=item.kamokuAve4;
      this.kamokuAve5=item.kamokuAve5;
      this.kamokuAve6=item.kamokuAve6;
      this.kamokuAve7=item.kamokuAve7;
      this.kamokuAve8=item.kamokuAve8;
      this.kamokuAve9=item.kamokuAve9;
      this.kamokuAve10=item.kamokuAve10;
      this.kamokuAve11=item.kamokuAve11;
      this.kamokuAve12=item.kamokuAve12;
      this.kamokuAve13=item.kamokuAve13;
      this.kamokuAve14=item.kamokuAve14;
      this.kamokuAve15=item.kamokuAve15;
      this.kamokuAve16=item.kamokuAve16;
      this.kamokuAve17=item.kamokuAve17;
      this.kamokuAve18=item.kamokuAve18;
      this.kamokuAve19=item.kamokuAve19;
      this.kamokuAve20=item.kamokuAve20;
      this.kamokuAve21=item.kamokuAve21;
      this.kamokuAve22=item.kamokuAve22;
      this.kamokuAve23=item.kamokuAve23;
      this.kamokuAve24=item.kamokuAve24;
      this.kamokuAve25=item.kamokuAve25;
      this.kamokuAve26=item.kamokuAve26;
      this.kamokuAve27=item.kamokuAve27;
      this.kamokuAve28=item.kamokuAve28;
      this.kamokuAve29=item.kamokuAve29;
      this.kamokuAve30=item.kamokuAve30;
      this.kamokuAve31=item.kamokuAve31;
      this.kamokuAve32=item.kamokuAve32;
      this.deviation1=item.deviation1;
      this.deviation2=item.deviation2;
      this.deviation3=item.deviation3;
      this.deviation4=item.deviation4;
      this.deviation5=item.deviation5;
      this.deviation6=item.deviation6;
      this.deviation7=item.deviation7;
      this.deviation8=item.deviation8;
      this.deviation9=item.deviation9;
      this.deviation10=item.deviation10;
      this.deviation11=item.deviation11;
      this.deviation12=item.deviation12;
      this.deviation13=item.deviation13;
      this.deviation14=item.deviation14;
      this.deviation15=item.deviation15;
      this.deviation16=item.deviation16;
      this.deviation17=item.deviation17;
      this.deviation18=item.deviation18;
      this.deviation19=item.deviation19;
      this.deviation20=item.deviation20;
      this.deviation21=item.deviation21;
      this.deviation22=item.deviation22;
      this.deviation23=item.deviation23;
      this.deviation24=item.deviation24;
      this.deviation25=item.deviation25;
      this.deviation26=item.deviation26;
      this.deviation27=item.deviation27;
      this.deviation28=item.deviation28;
      this.deviation29=item.deviation29;
      this.deviation30=item.deviation30;
      this.deviation31=item.deviation31;
      this.deviation32=item.deviation32;
      this.rank1=item.rank1;
      this.rank2=item.rank2;
      this.rank3=item.rank3;
      this.rank4=item.rank4;
      this.rank5=item.rank5;
      this.rank6=item.rank6;
      this.rank7=item.rank7;
      this.rank8=item.rank8;
      this.rank9=item.rank9;
      this.rank10=item.rank10;
      this.rank11=item.rank11;
      this.rank12=item.rank12;
      this.rank13=item.rank13;
      this.rank14=item.rank14;
      this.rank15=item.rank15;
      this.rank16=item.rank16;
      this.rank17=item.rank17;
      this.rank18=item.rank18;
      this.rank19=item.rank19;
      this.rank20=item.rank20;
      this.rank21=item.rank21;
      this.rank22=item.rank22;
      this.rank23=item.rank23;
      this.rank24=item.rank24;
      this.rank25=item.rank25;
      this.rank26=item.rank26;
      this.rank27=item.rank27;
      this.rank28=item.rank28;
      this.rank29=item.rank29;
      this.rank30=item.rank30;
      this.rank31=item.rank31;
      this.rank32=item.rank32;
      this.totalSeiseki=item.totalSeiseki;
      this.seisekiAve=item.seisekiAve;
      this.deviationAve=item.deviationAve;
      this.classRank=item.classRank;
      this.classDenominator=item.classDenominator;
      this.gakunenRank=item.gakunenRank;
      this.gakunenDenominator=item.gakunenDenominator;
      this.courseRank=item.courseRank;
      this.courseDenominator=item.courseDenominator;
      this.viewGradeDialog = true;
    },
    // 成績変数表示用の変数を一括初期化
    resetGrade(){
      this.kamokuNm1=null;
      this.kamokuNm2=null;
      this.kamokuNm3=null;
      this.kamokuNm4=null;
      this.kamokuNm5=null;
      this.kamokuNm6=null;
      this.kamokuNm7=null;
      this.kamokuNm8=null;
      this.kamokuNm9=null;
      this.kamokuNm10=null;
      this.kamokuNm11=null;
      this.kamokuNm12=null;
      this.kamokuNm13=null;
      this.kamokuNm14=null;
      this.kamokuNm15=null;
      this.kamokuNm16=null;
      this.kamokuNm17=null;
      this.kamokuNm18=null;
      this.kamokuNm19=null;
      this.kamokuNm20=null;
      this.kamokuNm21=null;
      this.kamokuNm22=null;
      this.kamokuNm23=null;
      this.kamokuNm24=null;
      this.kamokuNm25=null;
      this.kamokuNm26=null;
      this.kamokuNm27=null;
      this.kamokuNm28=null;
      this.kamokuNm29=null;
      this.kamokuNm30=null;
      this.kamokuNm31=null;
      this.kamokuNm32=null;
      this.seiseki1=null;
      this.seiseki2=null;
      this.seiseki3=null;
      this.seiseki4=null;
      this.seiseki5=null;
      this.seiseki6=null;
      this.seiseki7=null;
      this.seiseki8=null;
      this.seiseki9=null;
      this.seiseki10=null;
      this.seiseki11=null;
      this.seiseki12=null;
      this.seiseki13=null;
      this.seiseki14=null;
      this.seiseki15=null;
      this.seiseki16=null;
      this.seiseki17=null;
      this.seiseki18=null;
      this.seiseki19=null;
      this.seiseki20=null;
      this.seiseki21=null;
      this.seiseki22=null;
      this.seiseki23=null;
      this.seiseki24=null;
      this.seiseki25=null;
      this.seiseki26=null;
      this.seiseki27=null;
      this.seiseki28=null;
      this.seiseki29=null;
      this.seiseki30=null;
      this.seiseki31=null;
      this.seiseki32=null;
      this.kamokuAve1=null;
      this.kamokuAve2=null;
      this.kamokuAve3=null;
      this.kamokuAve4=null;
      this.kamokuAve5=null;
      this.kamokuAve6=null;
      this.kamokuAve7=null;
      this.kamokuAve8=null;
      this.kamokuAve9=null;
      this.kamokuAve10=null;
      this.kamokuAve11=null;
      this.kamokuAve12=null;
      this.kamokuAve13=null;
      this.kamokuAve14=null;
      this.kamokuAve15=null;
      this.kamokuAve16=null;
      this.kamokuAve17=null;
      this.kamokuAve18=null;
      this.kamokuAve19=null;
      this.kamokuAve20=null;
      this.kamokuAve21=null;
      this.kamokuAve22=null;
      this.kamokuAve23=null;
      this.kamokuAve24=null;
      this.kamokuAve25=null;
      this.kamokuAve26=null;
      this.kamokuAve27=null;
      this.kamokuAve28=null;
      this.kamokuAve29=null;
      this.kamokuAve30=null;
      this.kamokuAve31=null;
      this.kamokuAve32=null;
      this.deviation1=null;
      this.deviation2=null;
      this.deviation3=null;
      this.deviation4=null;
      this.deviation5=null;
      this.deviation6=null;
      this.deviation7=null;
      this.deviation8=null;
      this.deviation9=null;
      this.deviation10=null;
      this.deviation11=null;
      this.deviation12=null;
      this.deviation13=null;
      this.deviation14=null;
      this.deviation15=null;
      this.deviation16=null;
      this.deviation17=null;
      this.deviation18=null;
      this.deviation19=null;
      this.deviation20=null;
      this.deviation21=null;
      this.deviation22=null;
      this.deviation23=null;
      this.deviation24=null;
      this.deviation25=null;
      this.deviation26=null;
      this.deviation27=null;
      this.deviation28=null;
      this.deviation29=null;
      this.deviation30=null;
      this.deviation31=null;
      this.deviation32=null;
      this.rank1=null;
      this.rank2=null;
      this.rank3=null;
      this.rank4=null;
      this.rank5=null;
      this.rank6=null;
      this.rank7=null;
      this.rank8=null;
      this.rank9=null;
      this.rank10=null;
      this.rank11=null;
      this.rank12=null;
      this.rank13=null;
      this.rank14=null;
      this.rank15=null;
      this.rank16=null;
      this.rank17=null;
      this.rank18=null;
      this.rank19=null;
      this.rank20=null;
      this.rank21=null;
      this.rank22=null;
      this.rank23=null;
      this.rank24=null;
      this.rank25=null;
      this.rank26=null;
      this.rank27=null;
      this.rank28=null;
      this.rank29=null;
      this.rank30=null;
      this.rank31=null;
      this.rank32=null;
      this.totalSeiseki=null;
      this.seisekiAve=null;
      this.deviationAve=null;
      this.classRank=null;
      this.classDenominator=null;
      this.gakunenRank=null;
      this.gakunenDenominator=null;
      this.courseRank=null;
      this.courseDenominator=null;
    },

    // 検索(連絡用)=================================================================================
    // タイトルフィルタ
    titleFilter1(value, search, item) {
      if(!search)return true;
      return (item.title.indexOf(search) >= 0 || item.body.indexOf(search) >= 0);
    },
    // 未読フィルタ
    unreadFilter1(value) {
      if (!this.viewUnreadOnly1) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter1(value) {
      if (!this.viewFavoriteOnly1) {
        return true;
      }
      return value;
    },
    changeFilter1(flg){
      if(this.viewUnreadOnly1 && this.viewFavoriteOnly1){
        if(flg){
          this.viewFavoriteOnly1 = false;
        } else {
          this.viewUnreadOnly1 = false;
        }
      }
    },
    // 検索(アンケート用)=================================================================================
    // タイトルフィルタ
    titleFilter2(value, search, item) {
      if(!search)return true;
      return (item.title.indexOf(search) >= 0 || item.body.indexOf(search) >= 0);
    },
    // 未読フィルタ
    unreadFilter2(value) {
      if (!this.viewUnreadOnly2) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter2(value) {
      if (!this.viewFavoriteOnly2) {
        return true;
      }
      return value;
    },
    changeFilter2(flg){
      if(this.viewUnreadOnly2 && this.viewFavoriteOnly2){
        if(flg){
          this.viewFavoriteOnly2 = false;
        } else {
          this.viewUnreadOnly2 = false;
        }
      }
    },

    // 検索モーダル用 =================================================================================
    // 保健室
    doHealthRoomFilter1() {
      this.healthFilterFlg = true;
      this.healthFilterDialog = false;
    },
    clearHealthRoomFilter(){
      this.healthTitleFilterValue = "";
      this.healthViewUnreadOnly = false;

      this.healthFilterFlg = false;
      this.healthFilterDialog = false;
    },
    doHealthRoomFilter() {
      this.healthFilterFlg = true;
      this.healthFilterDialog = false;
    },
    doFilter1() {
      this.isFilterFlg1 = true;
      this.filterDialog1 = false;
    },
    clearFilter1(){
      this.titleFilterValue1 = "";
      this.viewUnreadOnly1 = false;
      this.viewFavoriteOnly1 = false;

      this.isFilterFlg1 = false;
      this.filterDialog1 = false;
    },
    doFilter2() {
      this.isFilterFlg2 = true;
      this.filterDialog2 = false;
    },
    clearFilter2(){
      this.titleFilterValue2 = "";
      this.viewUnreadOnly2 = false;
      this.viewFavoriteOnly2 = false;

      this.isFilterFlg2 = false;
      this.filterDialog2 = false;
    },
  },  
};
</script>

<style scoped>
.do-style {
  background-color: rgb(182, 182, 182)
}
.not-yet-style {
  background-color: rgb(255, 255, 255)
}
.v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>