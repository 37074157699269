<template>
    <div>
        <v-navigation-drawer v-model="primaryMenu" fixed temporary>
            <v-list>
                <v-list-item v-for="item in selectedPrimary" :key="item.id" link>
                    <!-- サブテキスト設定時 -->
                    <v-tooltip right v-if="item.text">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-content v-bind="attrs" v-on="on" @click="item.action">
                                <v-list-item-title>
                                    <v-icon>{{ item.icon }}</v-icon>
                                    {{ item.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <span>{{ item.text }}</span>
                    </v-tooltip>
                    
                    <!-- サブテキスト未設定時 -->
                    <v-list-item-content @click="item.action" v-if="!item.text">
                        <v-list-item-title>
                            <v-icon>{{ item.icon }}</v-icon>
                            {{ item.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="settingMenu" fixed temporary>
            <v-list>
                <v-list-item v-for="item in selectedSettings" :key="item.id" link>
                    <!-- サブテキスト設定時 -->
                    <v-tooltip right v-if="item.text">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-content v-bind="attrs" v-on="on" @click="item.action">
                                <v-list-item-title>
                                    <v-icon>{{ item.icon }}</v-icon>
                                    {{ item.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <span>{{ item.text }}</span>
                    </v-tooltip>
                
                    <!-- サブテキスト未設定時 -->
                    <v-list-item-content @click="item.action" v-if="!item.text">
                        <v-list-item-title>
                            <v-icon>{{ item.icon }}</v-icon>
                            {{ item.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="healthMenu" fixed temporary>
        <v-list>
            <v-list-item v-for="item in selectedHealth" :key="item.id" link>
            <!-- サブテキスト設定時 -->
            <v-tooltip right v-if="item.text">
                <template v-slot:activator="{ on, attrs }">
                <v-list-item-content v-bind="attrs" v-on="on" @click="item.action">
                    <v-list-item-title>
                    <v-icon>{{ item.icon }}</v-icon>
                    {{ item.name }}
                    </v-list-item-title>
                </v-list-item-content>
                </template>
                <span>{{ item.text }}</span>
            </v-tooltip>
            
            <!-- サブテキスト未設定時 -->
            <v-list-item-content @click="item.action" v-if="!item.text">
                <v-list-item-title>
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.name }}
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="portfolioMenu" fixed temporary>
        <v-list>
            <v-list-item v-for="item in selectedPortfolio" :key="item.id" link>
            <!-- サブテキスト設定時 -->
            <v-tooltip right v-if="item.text">
                <template v-slot:activator="{ on, attrs }">
                <v-list-item-content v-bind="attrs" v-on="on" @click="item.action">
                    <v-list-item-title>
                    <v-icon>{{ item.icon }}</v-icon>
                    {{ item.name }}
                    </v-list-item-title>
                </v-list-item-content>
                </template>
                <span>{{ item.text }}</span>
            </v-tooltip>
            
            <!-- サブテキスト未設定時 -->
            <v-list-item-content @click="item.action" v-if="!item.text">
                <v-list-item-title>
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.name }}
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    computed: {
        selectedPrimary() {
            return this.primaryMenuItems.filter(
                (item) => item.pattern <= this.$root.menuPattern
            );
        },
        selectedSettings() {
            return this.settingMenuItems.filter(
                (item) => item.pattern <= this.$root.menuPattern
            );
        },
        selectedHealth() {
            return this.healthMenuItems.filter(
                (item) => item.pattern <= this.$root.menuPattern
            );
        },
        selectedPortfolio() {
            return this.portfolioMenuItems.filter(
                (item) => item.pattern <= this.$root.menuPattern
            );
        },
    },
    
    data() {
        return {
            primaryMenu: false,
            settingMenu: false,
            healthMenu: false,
            portfolioMenu: false,

            primaryMenuItems: [
                // 教員メニュー
                {
                    id: 0,
                    icon: "mdi-email-plus",
                    name: "連絡の追加",
                    text: "",
                    action: this.toContactRegist,
                    pattern: 1,
                },
                {
                    id: 1,
                    icon: "mdi-table-plus",
                    name: "アンケートの追加",
                    text: "",
                    action: this.toSurveyRegist,
                    pattern: 1,
                },
                {
                    id: 2,
                    icon: "mdi-email-edit",
                    name: "連絡管理",
                    text: "編集や公開状況の確認",
                    action: this.toContactList,
                    pattern: 1,
                },
                {
                    id: 3,
                    icon: "mdi-table-edit",
                    name: "アンケート管理",
                    text: "編集や記入状況の確認",
                    action: this.toSurveyList,
                    pattern: 1,
                },
                {
                    id: 4,
                    icon: "mdi-message-cog",
                    name: "個別連絡管理",
                    text: "参加者の変更や連絡の終了",
                    action: this.toTalkRoomList,
                    pattern: 1,
                },
                {
                    id: 5,
                    icon: "mdi-account-multiple-check",
                    name: "出欠・早退の追加",
                    text: "＋パスワード初期化",
                    action: this.toGroupSelect,
                    pattern: 1,
                },
                //{
                //    id: 6,
                //    icon: "mdi-folder-multiple-outline",
                //    name: "ポートフォリオ",
                //    text: "課題、活動記録、学習のまとめの管理",
                //    action: this.openPortfolioMenu,
                //    pattern: 1,
                //},
                {
                    id: 6,
                    icon: "mdi-account-group",
                    name: "グループ管理",
                    text: "登録やメンバーの割当",
                    action: this.toGroupList,
                    pattern: 1,
                },
                {
                  id: 7,
                  icon: "mdi-train",
                  name: "交通機関情報",
                  text: "公共交通機関の遅延情報の登録",
                  action: this.toTrafficList,
                  pattern: 1,
                },
                {
                    id: 8,
                    icon: "mdi-barcode-scan",
                    name: "バーコード受付",
                    text: "学生証スキャンによる出欠状況更新",
                    action: this.toBarcodeCheckIn,
                    pattern: 1,
                },
                {
                    id: 9,
                    icon: "mdi-database-search-outline",
                    name: "出欠集計確認",
                    text: "出欠集計(HOME)＋遅刻者一覧表出力",
                    action: this.toAttendanceSummary,
                    pattern: 1,
                },
                {
                    id: 10,
                    icon: "mdi-publish",
                    name: "成績開示",
                    text: "教務システムと連動した帳票開示設定",
                    action: this.toGradePublishSettings,
                    pattern: 1,
                },
                {
                    id: 11,
                    icon: "mdi-human-handsup",
                    name: "健康管理",
                    text: "保健室の入退室など",
                    action: this.openHealthMenu,
                    pattern: 1,
                },
                {
                    id: 12,
                    icon: "mdi-cog-outline",
                    name: "システム設定",
                    action: this.openSettingMenu,
                    pattern: 2,
                },
                {
                    id: 13,
                    icon: "mdi-card-account-mail",
                    name: "メールアドレス設定",
                    action: this.toProfileSetting,
                    pattern: 1,
                },
                {
                    id: 14,
                    icon: "mdi-note-text-outline",
                    name: "利用規約",
                    action: this.toTermsOfService,
                    pattern: 1,
                },
            ],
            // 設定メニュー
            settingMenuItems: [
                {
                    id: 0,
                    icon: "mdi-keyboard-backspace",
                    name: "戻る",
                    text: "メニューを一つ上の階層に切り替え",
                    action: this.openPrimaryMenu,
                    pattern: 1,
                },
                {
                    id: 1,
                    icon: "mdi-database-edit",
                    name: "設定変更",
                    text: "出欠ロック日付設定の変更",
                    action: this.toSettingEdit,
                    pattern: 1,
                },
                {
                    id: 2,
                    icon: "mdi-debug-step-over",
                    name: "年度更新",
                    text: "HOMEとしての年度更新",
                    action: this.toAnnualUpdate,
                    pattern: 1,
                },
            ],
            // 健康メニュー
            healthMenuItems: [
                {
                    id: 0,
                    icon: "mdi-keyboard-backspace",
                    name: "戻る",
                    text: "メニューを一つ上の階層に切り替え",
                    action: this.openPrimaryMenu,
                    pattern: 1,
                },
                {
                    id: 1,
                    icon: "mdi-medical-bag",
                    name: "保健室受付",
                    text: "入退室状況の登録・更新",
                    action: this.toHealthRoomReception,
                    pattern: 1,
                },
            ],
            // ポートフォリオメニュー
            portfolioMenuItems: [
                {
                    id: 0,
                    icon: "mdi-keyboard-backspace",
                    name: "戻る",
                    text: "メニューを一つ上の階層に切り替え",
                    action: this.openPrimaryMenu,
                    pattern: 1,
                },
                {
                    id: 1,
                    icon: "mdi-folder-plus",
                    name: "課題登録と提出状況",
                    text: "課題登録と活動記録や学習のまとめの確認",
                    action: this.toPoGroupSelect,
                    pattern: 1,
                },
                {
                    id: 2,
                    icon: "mdi-folder-eye",
                    name: "課題一覧",
                    text: "所属グループまたぎの課題一覧",
                    action: this.toPoHomeworkListForSelf,
                    pattern: 1,
                },
                {
                    id: 3,
                    icon: "mdi-update",
                    name: "活動記録タイムライン",
                    text: "活動記録の提出内容やコメントの確認",
                    action: this.toPoActivityRecordTimeline,
                    pattern: 1,
                },
                {
                    id: 4,
                    icon: "mdi-archive-clock",
                    name: "学習のまとめタイムライン",
                    text: "学習のまとめの提出内容やコメントの確認",
                    action: this.toPoStoryTimeline,
                    pattern: 1,
                },
            ],
        }
    },
    methods: {
        openDrawer() {
            this.primaryMenu = true;
        },
        // プロフィール設定画面
        toProfileSetting() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "ProfileSetting",
            });
        },
        // 利用規約確認画面
        toTermsOfService() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "TermsOfService",
            });
        },
        // 連絡登録
        toContactRegist() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "ContactRegist",
            });
        },
        // アンケート登録
        toSurveyRegist() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "SurveyRegist",
            });
        },

        // 連絡一覧画面 ⇒ 連絡訂正・未読/既読確認
        toContactList() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "ContactList",
            });
        },
        // アンケート一覧画面 ⇒ アンケート訂正・未読/既読確認
        toSurveyList() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "SurveyList",
            });
        },
        // 個別連絡一覧
        toTalkRoomList() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "TalkRoomList",
            });
        },
        // グループ選択 ⇒ 生徒一覧 ⇒ 出欠訂正 or 早退登録
        toGroupSelect() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "GroupSelect",
            });
        },
        // グループ設定画面
        toGroupList() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "GroupList",
            });
        },
        // バーコード受付画面
        toBarcodeCheckIn() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "BarcodeCheckIn",
            });
        },
        // 交通機関情報画面
        toTrafficList() {
          this.isActive = undefined;
          this.$router.push({
            name: "TrafficDelayList",
          });
        },
        // 活動記録タイムラインへ
        toPoActivityRecordTimeline() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "PoActivityRecordTimeline",
            });
        },
        // 学習のまとめタイムラインへ
        toPoStoryTimeline() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "PoStoryTimeline",
            });
        },
        // 課題グループ選択
        toPoGroupSelect() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "PoGroupSelect",
            });
        },
        // 課題一覧
        toPoHomeworkListForSelf() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "PoHomeworkListForSelf",
            });
        },
        // 出欠集計確認
        toAttendanceSummary() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "AttendanceSummary",
            });
        },
        // 成績開示設定
        toGradePublishSettings() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "GradePublishSettings",
            });
        },
        // 保健室受付
        toHealthRoomReception() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "HealthRoomReception",
            });
        },
        // 設定変更
        toSettingEdit() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "SettingEdit",
            });
        },
        // 年度更新
        toAnnualUpdate() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "AnnualUpdate",
            });
        },

        // メニュー階層制御
        // 設定メニューを開く
        openSettingMenu() {
            this.primaryMenu = false;
            this.settingMenu = true;
        },
        // 健康メニューを開く
        openHealthMenu() {
            this.primaryMenu = false;
            this.healthMenu = true;
        },
        // 課題メニューを開く
        openPortfolioMenu() {
            this.primaryMenu = false;
            this.portfolioMenu = true;
        },
        // 上階層に戻る
        openPrimaryMenu() {
            this.settingMenu = false;
            this.healthMenu = false;
            this.portfolioMenu = false;
            this.primaryMenu = true;
        },
    }
}
</script>