<template>
    <v-container fluid>
      <!-- 連絡ブロック -->
      <v-card
          class="d-flex justify-center flex-column mx-auto my-6 flat"
          width="1200"
          color="#fff"
        >
              <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
                  <p >{{ mailTitle }}</p>
              </v-card-title>
              <v-card-text class="d-flex justify-center flex-column">
              <v-row dense>
                <v-col dense cols="12" justify="center">
                  投稿者 : {{ userNm }}
                </v-col>
              </v-row>
              <v-row class="ml-2 black--text" dense >
                <v-col dense cols="12" justify="center">
                  保健室からの連絡です
                </v-col>
              </v-row>
              <br>
              <br>
              <v-row class="ml-2 black--text" dense>
                  症状 : <br>
              </v-row>
              <v-row class="ml-4 black--text" dense>
                <v-col dense cols="12" justify="center">
                  {{ shoujo }}
                </v-col>
              </v-row>              
              <v-row class="ml-2 black--text" dense>
                  処置 : <br>
              </v-row>
              <v-row class="ml-4 black--text" dense>
                <v-col dense cols="12" justify="center">
                  {{ shochi }}
                </v-col>
              </v-row>
              <v-row class="ml-4 black--text" dense>
                <v-col dense cols="12" justify="center">
                  <br>
                  <br>
                  {{ comment }}
                </v-col>
              </v-row>
              </v-card-text>
            <v-list>
        </v-list>
      </v-card>
    </v-container>
  </template>
  <script>
import '@uppy/core/dist/style.css'
import { httpsCallable, getFunctions } from "firebase/functions";
  export default {
    props: ['studentId','mailTitle','shochi','shoujo','comment', 'unreadFlg', 'raishitsuId', 'userNm'],
    // 初期表示時データ読み込み
    async created() {
      await this.load();
    },
    methods: {
      async load() {
        // ローディング表示
        this.$root.loading = true;
        const functions = getFunctions(this.$firebase);
        functions.region = "asia-northeast1";
        var data = {
          funcName: "loadContactHealthRoom",
          studentId: this.studentId,
          unreadFlg : this.unreadFlg,
          raishitsuId : this.raishitsuId,
        }
        const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
        await schoolAidHomeLogined(data);
        // ローディング非表示
        this.$root.loading = false;
      },
    },  
  };
  </script>