<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          交通機関情報
        </v-card>
      </v-col>
    </v-row>
    <ValidationObserver ref="obsAllSetting" v-slot="{ invalid: allSettingInvalid }">
      <v-row dense>
        <v-col cols="3">
          <!-- 検索用のテキスト入力 -->
          <v-text-field
            v-model="search"
            label="検索"
            placeholder="検索"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          ></v-text-field>
        </v-col>
        <v-col cols="2" justify="center">
          <ValidationProvider name="対象日" rules="required" v-slot="{ errors }">
            <v-text-field
                v-model="targetDate"
                label="対象日"
                placeholder=""
                :error-messages="errors[0]"
                type="date"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                @input="onChangeTargetDate(targetDate)"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="2">
          <ValidationProvider name="遅延時間" rules="max:4|numeric" v-slot="{ errors }">
            <v-text-field
                v-model="allSetting"
                label="一括設定"
                placeholder=""
                :error-messages="errors[0]"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :disabled="pastFlg"
                @change="zeroSuppress()"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="1">
          <v-btn
          color="grey"
          class="ml-auto mt-0 black--text"
          @click="onClickAllSetting"
          :disabled="pastFlg || allSettingInvalid"
          >一括設定</v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn
          color="accent"
          class="ml-auto mt-0 black--text"
          @click="onClickDelayRegist"
          :disabled="pastFlg"
          >登録・更新</v-btn>
        </v-col>
      </v-row>
    </ValidationObserver>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="traffics"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          disable-sort
          :loading="isListLoding" loading-text="読込中"
          :search="search"
          >
          <template #[`item.delayMinutesAfter`]="{ item }">
            <ValidationObserver ref="obsAllSetting" v-slot="{ invalid: registInvalid }">
              <ValidationProvider name="遅延時間" rules="max:4|numeric" v-slot="{ errors }">
                <v-text-field
                  v-model="item.delayMinutesAfter"
                  label="遅延時間（分）"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="pastFlg"
                  @change="onChangeDelayMinutes(item, registInvalid)"
                ></v-text-field>
              </ValidationProvider>
            </ValidationObserver>
          </template>
          <template #[`item.updFlg`]="{ item }">
            <v-text-field
              v-model="item.updFlg"
              v-show=false
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </template>
          <template #[`item.errFlg`]="{ item }">
            <v-text-field
              v-model="item.errFlg"
              v-show=false
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="9" class="d-flex text-right">
        <v-btn
        color="accent"
        class="ml-auto mt-0 black--text"
        @click="onClickDelayRegist"
        :disabled="pastFlg"
        >登録・更新</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "コード", value: "kotuKikanCd", width: "10%"},
        { text: "交通機関名称", value: "kotuKikanNm", width: "20%"},
        { text: "【現在の設定値】", value: "delayMinutesBefore", width: "10%"},
        { text: "【変更後】", value: "delayMinutesAfter", width: "20%"},
        { text: "", value: "updText", width: "40%"},
        { text: "", value: "updFlg", width: "0%"},
        { text: "", value: "errFlg", width: "0%"},
      ],
      traffics: [],
      isListLoding: false,
      perPage: 30,
      targetDate : "",
      allSetting : "",
      pastFlg : false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.perPage = this.$store.state.parPageTrafficDelayList;
    await this.reload(this.formatDate(new Date()));
  },
	watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageTrafficDelayList', newVal);
    },
  },
  methods: {
    // 画面再読み込み
    async reload(targetDate) {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      var param = {
        funcName: "loadTrafficDelayList",
        targetDate: targetDate,
      };
      await schoolAidHomeLogined(param).then((traffics) => {
        var ret = [];
        traffics.data.forEach((traffic) => {
          var row = {
            kotuKikanCd: traffic.code,
            kotuKikanNm: traffic.name,
            delayMinutesBefore: traffic.delayMinutes,
            delayMinutesAfter: traffic.delayMinutes,
            updText: "　　　",
            updFlg: false,
            errFlg: false,
          };
          ret.push(row);
        });
        this.traffics = ret;
        this.isListLoding = false;
        this.targetDate = targetDate;
        // 本日でなければ非活性
        if (targetDate == this.formatDate(new Date())) {
          this.pastFlg = false;
        } else {
          this.pastFlg = true;
        }
      });
    },

    // 日付をyyyy-mm-ddの形に成形する
    formatDate(dt){
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },

    // 遅延時間の値を変更
    onChangeDelayMinutes(item, registInvalid) {
      var delayMinutesBefore = (item.delayMinutesBefore == null) ? "" : item.delayMinutesBefore;
      item.errFlg = registInvalid;
      item.delayMinutesAfter = item.delayMinutesAfter.replace(/^0+/, '');
      if (delayMinutesBefore == item.delayMinutesAfter) {
        // 現在設定値から変更なし
        item.updText = "　　　";
        item.updFlg = false;
      } else {
        // 現在設定値から変更あり
        item.updText = "編集中";
        item.updFlg = true;
      }
    },

    // 数値の頭の0を削除する
    zeroSuppress() {
      this.allSetting = this.allSetting.replace(/^0+/, '');
    },

    // 一括設定ボタン押下
    onClickAllSetting() {
      var minutes = this.allSetting;
      for (var traffic of this.traffics) {
        traffic.delayMinutesAfter = minutes;
        // nullは空文字に置換する
        var delayMinutesBefore = (traffic.delayMinutesBefore == null) ? "" : traffic.delayMinutesBefore;
        if (delayMinutesBefore == traffic.delayMinutesAfter) {
          // 現在設定値から変更なし
          traffic.updText = "　　　";
          traffic.updFlg = false;
        } else {
          // 現在設定値から変更あり
          traffic.updText = "編集中";
          traffic.updFlg = true;
        }
      }
    },

    // 登録・更新ボタン押下
    async onClickDelayRegist() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var trafficDetails = [];
      var errList = [];
      for (var traffic of this.traffics) {
        if (traffic.updFlg) {
          trafficDetails.push(traffic);
          if (traffic.errFlg) {
            errList.push("\r\n" + traffic.kotuKikanCd + "：" + traffic.kotuKikanNm);
          }
        }
      }
      if (trafficDetails.length == 0) {
        alert("更新データがありません。");
        return;
      }
      if (errList.length > 0) {
        alert("以下の入力に誤りがあります。" + errList.join());
        return;
      }
      this.isListLoding = true;
      var param = {
        funcName: "onClickDelayRegist",
        trafficDetails: trafficDetails,
        targetDate: this.targetDate,
      };
      await schoolAidHomeLogined(param).then((result) => {
        // 更新後リロード
        this.reload(result.data);
      });
    },

    // 対象日を変更
    async onChangeTargetDate(targetDate) {
      await this.reload(targetDate);
    },
  },
};
</script>

<style>
</style>